<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="refreshData"
            >Refresh
          </v-btn>

          <v-btn color="success" min-width="100" :href="urlCreate"
            >Create
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      dense
      multi-sort
    >
      <template v-slot:item.title="{ item }">
        <a :href="urlShow(item.id)">
          {{ item.title }}
        </a>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    urlCreate: "/contents/contents-email/create",

    itemsPerPage: 10,
    headers: [
      {
        text: "id",
        value: "id",
      },
      {
        text: "title",
        value: "title",
      },
      {
        text: "writer",
        value: "writer",
      },
      {
        text: "createdAt",
        value: "createdAt",
      },
      {
        text: "updatedAt",
        value: "updatedAt",
      },
      {
        text: "toBePublishedAt",
        value: "toBePublishedAt",
      },
      {
        text: "publishedAt",
        value: "publishedAt",
      },
      {
        text: "cancelledAt",
        value: "cancelledAt",
      },
    ],
    items: [],

    snackbar: false,
    notificationMessage: "",
  }),
  computed: {
    urlShow() {
      return (id) => `/contents/contents-email/${id}`;
    },
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    async refreshData() {
      try {
        const res = await this.authGet("/api/contents/contents-email");
        this.items = res.data.data;
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },
  },
};
</script>
